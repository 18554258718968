const axios = require("axios")

const app = require("../../../package.json");

const requiresData = (method) => {
  return (method === 'POST' || method === 'PUT')
}

class CustomError extends Error {
  constructor(e) {
    super(e.message, e.code)
    this.name = e.name
    this.text = e.message
    this.code = e.code
    this.type = e.type
    this.data = e.data
  }
}

const callAPI = async (store, endpoint, params) => {
  const cartToken = store.cartToken()
  const sessionToken = store.sessionToken()

  try {
    const result = await axios({
      url: endpoint.href.startsWith('/') ? `${process.env.GATSBY_API_URL}${endpoint.href}` : endpoint.href,
      method: endpoint.method || 'GET',
      params: requiresData(endpoint.method) ? {} : params,
      data: requiresData(endpoint.method) ? params : {},
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'CartToken': cartToken ? cartToken : '',
        'CustomerToken': sessionToken ? sessionToken : '',
        'Lang': store && store.app && store.app.language ? store.app.language.id_lang : null,
        'X-Application-Name': app.name,
        'X-Application-Version': app.version
      }
    })
    if (result && result.status === 200) {
      return result.data
    }
  } catch (e) {
    // @todo catch generic errors and take action
    const data = e.response.data ? e.response.data : null
    throw new CustomError(data)
  }
  return null
}

export default callAPI
