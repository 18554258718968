// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-account-addresses-js": () => import("./../../../src/templates/account/addresses.js" /* webpackChunkName: "component---src-templates-account-addresses-js" */),
  "component---src-templates-account-giftlist-items-js": () => import("./../../../src/templates/account/giftlist_items.js" /* webpackChunkName: "component---src-templates-account-giftlist-items-js" */),
  "component---src-templates-account-giftlist-orders-js": () => import("./../../../src/templates/account/giftlist_orders.js" /* webpackChunkName: "component---src-templates-account-giftlist-orders-js" */),
  "component---src-templates-account-giftlists-js": () => import("./../../../src/templates/account/giftlists.js" /* webpackChunkName: "component---src-templates-account-giftlists-js" */),
  "component---src-templates-account-home-js": () => import("./../../../src/templates/account/home.js" /* webpackChunkName: "component---src-templates-account-home-js" */),
  "component---src-templates-account-orders-js": () => import("./../../../src/templates/account/orders.js" /* webpackChunkName: "component---src-templates-account-orders-js" */),
  "component---src-templates-account-wishlist-js": () => import("./../../../src/templates/account/wishlist.js" /* webpackChunkName: "component---src-templates-account-wishlist-js" */),
  "component---src-templates-actions-fromme-2023-js": () => import("./../../../src/templates/actions/fromme2023.js" /* webpackChunkName: "component---src-templates-actions-fromme-2023-js" */),
  "component---src-templates-actions-hay-2023-outdoor-js": () => import("./../../../src/templates/actions/hay2023outdoor.js" /* webpackChunkName: "component---src-templates-actions-hay-2023-outdoor-js" */),
  "component---src-templates-actions-moebe-2023-bed-js": () => import("./../../../src/templates/actions/moebe2023bed.js" /* webpackChunkName: "component---src-templates-actions-moebe-2023-bed-js" */),
  "component---src-templates-actions-muuto-2023-dining-js": () => import("./../../../src/templates/actions/muuto2023dining.js" /* webpackChunkName: "component---src-templates-actions-muuto-2023-dining-js" */),
  "component---src-templates-actions-pf-2023-js": () => import("./../../../src/templates/actions/pf2023.js" /* webpackChunkName: "component---src-templates-actions-pf-2023-js" */),
  "component---src-templates-actions-showroom-js": () => import("./../../../src/templates/actions/showroom.js" /* webpackChunkName: "component---src-templates-actions-showroom-js" */),
  "component---src-templates-actions-string-2023-elevate-js": () => import("./../../../src/templates/actions/string2023elevate.js" /* webpackChunkName: "component---src-templates-actions-string-2023-elevate-js" */),
  "component---src-templates-actions-upnorth-5-y-js": () => import("./../../../src/templates/actions/upnorth5y.js" /* webpackChunkName: "component---src-templates-actions-upnorth-5-y-js" */),
  "component---src-templates-actions-weekend-2023-js": () => import("./../../../src/templates/actions/weekend2023.js" /* webpackChunkName: "component---src-templates-actions-weekend-2023-js" */),
  "component---src-templates-brands-index-js": () => import("./../../../src/templates/brands/index.js" /* webpackChunkName: "component---src-templates-brands-index-js" */),
  "component---src-templates-brands-item-js": () => import("./../../../src/templates/brands/item.js" /* webpackChunkName: "component---src-templates-brands-item-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-checkout-giftwrapping-js": () => import("./../../../src/templates/checkout/giftwrapping.js" /* webpackChunkName: "component---src-templates-checkout-giftwrapping-js" */),
  "component---src-templates-checkout-guest-js": () => import("./../../../src/templates/checkout/guest.js" /* webpackChunkName: "component---src-templates-checkout-guest-js" */),
  "component---src-templates-checkout-index-js": () => import("./../../../src/templates/checkout/index.js" /* webpackChunkName: "component---src-templates-checkout-index-js" */),
  "component---src-templates-checkout-payment-js": () => import("./../../../src/templates/checkout/payment.js" /* webpackChunkName: "component---src-templates-checkout-payment-js" */),
  "component---src-templates-checkout-shipping-js": () => import("./../../../src/templates/checkout/shipping.js" /* webpackChunkName: "component---src-templates-checkout-shipping-js" */),
  "component---src-templates-checkout-status-js": () => import("./../../../src/templates/checkout/status.js" /* webpackChunkName: "component---src-templates-checkout-status-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-content-about-js": () => import("./../../../src/templates/content/about.js" /* webpackChunkName: "component---src-templates-content-about-js" */),
  "component---src-templates-content-appointment-js": () => import("./../../../src/templates/content/appointment.js" /* webpackChunkName: "component---src-templates-content-appointment-js" */),
  "component---src-templates-content-complaints-js": () => import("./../../../src/templates/content/complaints.js" /* webpackChunkName: "component---src-templates-content-complaints-js" */),
  "component---src-templates-content-customer-of-the-month-js": () => import("./../../../src/templates/content/customer_of_the_month.js" /* webpackChunkName: "component---src-templates-content-customer-of-the-month-js" */),
  "component---src-templates-content-delivery-js": () => import("./../../../src/templates/content/delivery.js" /* webpackChunkName: "component---src-templates-content-delivery-js" */),
  "component---src-templates-content-payment-js": () => import("./../../../src/templates/content/payment.js" /* webpackChunkName: "component---src-templates-content-payment-js" */),
  "component---src-templates-content-privacy-js": () => import("./../../../src/templates/content/privacy.js" /* webpackChunkName: "component---src-templates-content-privacy-js" */),
  "component---src-templates-content-returns-js": () => import("./../../../src/templates/content/returns.js" /* webpackChunkName: "component---src-templates-content-returns-js" */),
  "component---src-templates-content-string-byo-js": () => import("./../../../src/templates/content/string-byo.js" /* webpackChunkName: "component---src-templates-content-string-byo-js" */),
  "component---src-templates-content-team-js": () => import("./../../../src/templates/content/team.js" /* webpackChunkName: "component---src-templates-content-team-js" */),
  "component---src-templates-content-terms-js": () => import("./../../../src/templates/content/terms.js" /* webpackChunkName: "component---src-templates-content-terms-js" */),
  "component---src-templates-content-testimonials-js": () => import("./../../../src/templates/content/testimonials.js" /* webpackChunkName: "component---src-templates-content-testimonials-js" */),
  "component---src-templates-content-vacatures-js": () => import("./../../../src/templates/content/vacatures.js" /* webpackChunkName: "component---src-templates-content-vacatures-js" */),
  "component---src-templates-content-vacatures-js-developer-js": () => import("./../../../src/templates/content/vacatures/js-developer.js" /* webpackChunkName: "component---src-templates-content-vacatures-js-developer-js" */),
  "component---src-templates-createaccount-js": () => import("./../../../src/templates/createaccount.js" /* webpackChunkName: "component---src-templates-createaccount-js" */),
  "component---src-templates-giftlist-buy-index-js": () => import("./../../../src/templates/giftlist/buy/index.js" /* webpackChunkName: "component---src-templates-giftlist-buy-index-js" */),
  "component---src-templates-giftlist-create-index-js": () => import("./../../../src/templates/giftlist/create/index.js" /* webpackChunkName: "component---src-templates-giftlist-create-index-js" */),
  "component---src-templates-giftlist-index-js": () => import("./../../../src/templates/giftlist/index.js" /* webpackChunkName: "component---src-templates-giftlist-index-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-interioradvice-js": () => import("./../../../src/templates/interioradvice.js" /* webpackChunkName: "component---src-templates-interioradvice-js" */),
  "component---src-templates-lostpassword-js": () => import("./../../../src/templates/lostpassword.js" /* webpackChunkName: "component---src-templates-lostpassword-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/new.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-order-confirmation-js": () => import("./../../../src/templates/order/confirmation.js" /* webpackChunkName: "component---src-templates-order-confirmation-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-productfamily-js": () => import("./../../../src/templates/productfamily.js" /* webpackChunkName: "component---src-templates-productfamily-js" */),
  "component---src-templates-promo-greenfriday-js": () => import("./../../../src/templates/promo/greenfriday.js" /* webpackChunkName: "component---src-templates-promo-greenfriday-js" */),
  "component---src-templates-sale-js": () => import("./../../../src/templates/sale.js" /* webpackChunkName: "component---src-templates-sale-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-sku-js": () => import("./../../../src/templates/sku.js" /* webpackChunkName: "component---src-templates-sku-js" */)
}

