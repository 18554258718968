import React from 'react'
import { StateProvider } from './src/store/'

// @todo add sentry browser tracing

export const onClientEntry = () => {
  const app = require('./package.json')

  // see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
  if (process.env.GATSBY_GOOGLE_ANALYTICS) {
    if (window && window.ga) {
      window.ga('require', 'ec')
    }
  }

  if (process.env.GATSBY_SENTRY_DSN) {
    const Sentry = require('@sentry/browser')

    Sentry.init({
      dsn: process.env.GATSBY_SENTRY_DSN,
      release: app.version,
      environment: process.env.GATSBY_SENTRY_ENV || 'production',
    })
  }

  if (process.env.GATSBY_LOGROCKET) {
    const LogRocket = require('logrocket')
    const setupLogRocketReact = require('logrocket-react')
    LogRocket.init(process.env.GATSBY_LOGROCKET, {
      release: app.version,
      network: {
        isEnabled: false, // @todo causes issues with gatsby
      }
    })

    setupLogRocketReact(LogRocket)

    if (process.env.GATSBY_SENTRY_DSN) {
      const Sentry = require('@sentry/browser')
      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra("sessionURL", sessionURL)
        })
      })
    }
  }
}

export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
)

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true)
}
