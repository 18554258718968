import * as _ from 'lodash'
import callAPI from '../lib/callAPI'

const appInitialState = {
  language: null,
  initialized: false,
  error: null,
  endpoints: null,
  cartrules: null,
  loaded_endpoints: false,
  disabled: false,
  giftlist_adding: false,
  giftlist_removing: false,
  giftlist_updating: false
}

class AppProvider {
  use(dispatch, store) {
    this.store = store
    this.dispatch = dispatch
  }

  clone(o) {
    return _.cloneDeep(o)
  }

  async load(clonedState) {
    if (!clonedState.app.loaded_endpoints) {
      try {
        const result = await callAPI(clonedState, { href: `${process.env.GATSBY_API_URL}/v1` })
        if (result && result._links) {
          clonedState.app.endpoints = result._links
          clonedState.app.loaded_endpoints = true
          clonedState.app.error = null

          const cartrules = await callAPI(clonedState, result._links.cartrules)
          if (cartrules && cartrules._embedded && cartrules._embedded.cartrules) {
            clonedState.app.cartrules = cartrules._embedded.cartrules
          }
        }
      } catch (e) {
        console.error(e)

        clonedState.app.error = e
        clonedState.app.loaded_endpoints = false
        clonedState.app.initialized = true
      }
      return clonedState
    }
  }

  async finalize(clonedState) {
    clonedState.app.initialized = true
    return clonedState
  }

  /* ************************************************** */
  /* language */
  /* ************************************************** */

  async setLanguage(lang) {
    await this.dispatch((s) => {
      s.app.language = lang
      return s
    })
  }


  /* ************************************************** */
  /* giftlists */
  /* ************************************************** */

  async searchGiftlist(query) {
    const result = await callAPI(this.store, this.store.app.endpoints.giftlists, { search: query })
    return result
  }

  async myGiftlists() {
    return callAPI(
      this.store,
      this.store.app.endpoints.my_giftlists
    )
  }

  async myGiftlist(id_giftlist) {
    return callAPI(
      this.store,
      {
        href: this.store.app.endpoints.my_giftlist.href.replace('{id_giftlist}', id_giftlist),
        method: this.store.app.endpoints.my_giftlist.method
      }
    )
  }

  async createGiftlist(name, name_parents, welcome_message, gender, published) {
    return callAPI(
      this.store,
      {
        href: this.store.app.endpoints.my_giftlists.href,
        method: 'POST'
      },
      {
        name: name,
        name_parents: name_parents,
        welcome_message: welcome_message,
        gender: gender,
        published: published
      }
    )
  }

  async updateGiftlistItem(item) {
    await this.dispatch((s) => {
      s.app.disabled = true
      return s
    })

    try {
      const endpoint = {
        href: `/v1/my/giftlists/${item.id_giftlist}/${item.id_giftlist_product}`,
        method: 'PUT'
      }

      await callAPI(this.store, endpoint, {
        quantity: item.quantity,
        priority: item.priority
      })

      await this.dispatch((s) => {
        s.app.disabled = false
        return s
      })

      return true
    } catch (e) {
      await this.dispatch((s) => {
        s.app.disabled = false
        return s
      })

      return false
    }
  }

  async removeGiftlistItem(item) {
    await this.dispatch((s) => {
      s.app.disabled = true
      return s
    })

    try {
      if (item && item._links && item._links.remove) {
        await callAPI(this.store, item._links.remove)
      }

      await this.dispatch((s) => {
        s.app.disabled = false
        return s
      })

      return true
    } catch (e) {
      await this.dispatch((s) => {
        s.app.disabled = false
        return s
      })

      return false
    }
  }

  async addToGiftlist(id_giftlist, sku) {
    await this.dispatch((s) => {
      s.app.disabled = true
      s.app.giftlist_adding = sku.id_sku
      return s
    })

    try {
      const endpoint = {
        href: `/v1/my/giftlists/${id_giftlist}/${sku.id_product}/${sku.id_product_attribute}`,
        method: 'POST'
      }

      const item = await callAPI(this.store, endpoint)

      await this.dispatch((s) => {
        s.app.disabled = false
        s.app.giftlist_adding = null
        return s
      })

      return { item: item, error: null }
    } catch (e) {
      await this.dispatch((s) => {
        s.app.disabled = false
        s.app.giftlist_adding = null
        return s
      })

      return { item: null, error: e }
    }
  }

  /* ************************************************** */

  async newsletterSubscribe(email, firstName, lastName, id_lang) {
    return callAPI(this.store, this.store.app.endpoints.newsletter, { email: email, firstName: firstName, lastName: lastName, id_lang: id_lang })
  }

  /* ************************************************** */
}

export { AppProvider, appInitialState }
