import React, { createContext, useReducer, useEffect } from 'react'
import * as _ from 'lodash'

import { AppProvider, appInitialState } from './app'
import { LayoutProvider, layoutInitialState } from './layout'
import { SessionProvider, sessionInitialState } from './session'
import { CartProvider, cartInitialState } from './cart'

const initialState = {
  app: appInitialState,
  layout: layoutInitialState,
  session: sessionInitialState,
  cart: cartInitialState,
  cartToken: function() {
    return this && this.cart && this.cart.token ? this.cart.token : ''
  },
  sessionToken: function() {
    return this && this.session && this.session.token ? this.session.token : ''
  }
}

const context = createContext(initialState)

const clone = (o) => {
  return _.cloneDeep(o)
}

const reducer = (state, action) => {
  return action(clone(state))
}

const appProvider = new AppProvider()
const layoutProvider = new LayoutProvider()
const sessionProvider = new SessionProvider()
const cartProvider = new CartProvider()

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  appProvider.use(dispatch, state)
  layoutProvider.use(dispatch, state)
  sessionProvider.use(dispatch, state)
  cartProvider.use(dispatch, state)

  useEffect(() => {
    const initialize = async (dispatch, state) => {
      let clonedState = null

      if (!state.app.initialized && !state.app.loaded_endpoints) {
        clonedState = clone(state)
        clonedState = await appProvider.load(clonedState)
      }

      if (!state.app.initialized && state.app.loaded_endpoints) {
        clonedState = clone(state)
        clonedState = await sessionProvider.load(clonedState)
        clonedState = await cartProvider.load(clonedState)
        clonedState = await appProvider.finalize(clonedState)
      }

      if (clonedState) {
        dispatch((s) => {
          s.app = clonedState.app
          s.session = clonedState.session
          s.cart = clonedState.cart
          return s
        })
      }
    }

    initialize(dispatch, state)
  }, [state]);

  // if (typeof window !== 'undefined' && process.env.GATSBY_REDIRECT_WWW) {
  //   const url = window.location.href
  //   if (url.includes('www.')) {
  //     window.location.replace(url.replace('www.', '').replace('http://', 'https://'))
  //     return <div />
  //   }
  // }

  return (
    <context.Provider value={{ store: state, appProvider, layoutProvider, sessionProvider, cartProvider }}>
      {children}
    </context.Provider>
  )
}

export { StateProvider, context }
