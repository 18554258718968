const layoutInitialState = {
  showsidebarright: false,
  cartresult: null,
  giftlistresult: null,
  addressdelete: null,
  addressadd: null,
  addcombination: null,
  addvoucher: null,
  data: null,
  close: null
}

class LayoutProvider {
  use(dispatch, store) {
    this.store = store
    this.dispatch = dispatch
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async showCartResult(result) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.cartresult = result || { event: 'show' }
      return s
    })
  }

  async showAddCombination2Cart(product) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.addcombination = product
      return s
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async showGiftlistResult(result) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.giftlistresult = result || { event: 'show' }
      return s
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async showCountrySelector(result) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.countryselector = result || {}
      return s
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async showAddressDelete(result) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.addressdelete = result || {}
      return s
    })
  }

  async showAddressCreate(id_country, id_carrier, refresh) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.addressadd = true
      s.layout.data = {
        id_country: id_country,
        id_carrier: id_carrier,
        refresh: refresh
      }
      return s
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async showAddVoucher(options) {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      s.layout.addvoucher = true
      if (options && options.close) s.layout.close = options.close
      return s
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async close() {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout = layoutInitialState
      return s
    })
  }

  async showSideBarRight() {
    await this.dispatch((s) => {
      s.layout = Object.assign({}, layoutInitialState)
      s.layout.showsidebarright = true
      return s
    })
  }
}

export { LayoutProvider, layoutInitialState }
